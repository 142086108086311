<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">ユーザーの一括作成</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <a-steps :current="current">
                <a-step v-for="item in steps" :key="item.title" :title="item.title" />
              </a-steps>
              <div style="margin-top: 40px; padding-bottom: 30px;">
                <div v-if="current === 0">
                  <p>テンプレートファイルをダウンロードし、作成したいユーザーをエクセルにて入力してください。作成しましたらページ下部よりファイルをアップロードしてください。</p>
                  <a-card title="ユーザーの一括作成テンプレート" style="margin-bottom: 30px;">
                    <p class="text-center" style="font-size: 1.2em"><a :href="templateUrl" class="text-primary"><i class="fa fa-file-excel-o" /> テンプレートファイル</a></p>
                    <p>
                      1行目は変更しないでください。<br>
                      2行目はサンプルとしていれているので、2行目から書き換えてお使いください。<br>
                      ※以下項目の選択肢についてです。それぞれ数値を入力してください。<br>
                      <strong>権限</strong>は [2:支店管理者,3:ドライバー]（会社管理者は一括では作成できません。）<br>
                      <strong>所属支店は支店ID</strong>を入力してください。複数支店に所属させる場合は、<strong>支店ID</strong>をカンマ区切りで入力してください。<br>
                      <strong>支店ID</strong>は [<span v-for="item in branchesAll" :key="item.id+item.name">{{ item.id }}: {{ item.name }}, </span>]<br>
                      <strong>解析対象</strong>は [0:解析対象にしない,1:解析対象にする]<br>
                      <span v-if="myRole === 0">
                        <strong>点呼代行対象</strong>は [0:対象にしない,1:対象にする]<br>
                        <strong>代行利用制限</strong>は [0:全ての点呼を代行にする,1:平日の日中は代行にしない,2:土日祝は代行にしない]<br>
                        <strong>PLUSの使用</strong>は [0:使用しない,1:使用する]<br>
                        <strong>連動検知器の使用</strong>は [0:使用しない,1:使用する]<br>
                      </span>
                      <strong>点呼権限</strong>は [0:権限に従う,1:支店管理者と同等にする]<br>
                      <strong>運行計画表の被閲覧</strong>は [0:不許可,1:許可]<br>
                    </p>
                    <div v-if="freeItemUserList">
                      会社ごとに設定した項目は以下の[]内の値を1行目に追記してください。<br>
                      <span v-for="item in freeItemUserList" :key="item.id">
                        [FreeItemUser{{ item.id}}]: {{ item.name }} <br>
                      </span>
                    </div>
                  </a-card>
                  <a-upload-dragger
                    name="file"
                    :multiple="false"
                    :beforeUpload="beforeUpload"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">
                      クリックしてファイルを選択 または ファイルをドラッグ してください。
                    </p>
                    <p class="ant-upload-hint">
                      ファイルはテンプレートに沿って作られたものに限ります。
                    </p>
                  </a-upload-dragger>
                </div>
                <div v-if="current === 1">
                  <h5 v-if="errorCount" class="text-danger" style="margin-bottom: 20px;">入力にエラーがあります。背景に<span class="wrongCell">色がついているセル</span>を確認してください。</h5>
                  <a-table
                    :columns="columns"
                    :data-source="excelData"
                    rowKey="メールアドレス"
                    :pagination="false"
                    style="margin-bottom: 30px;"
                  >
                    <span slot="branches" slot-scope="branches">{{ branchesName(branches) }}</span>
                    <span slot="name" slot-scope="name">{{ name }}</span>
                    <span slot="phonetic" slot-scope="phonetic">{{ phonetic }}</span>
                    <span slot="email" slot-scope="email">{{ email }}</span>
                    <span slot="role" slot-scope="role" >{{ role == 2 ? '支店管理者' : role == 3 ? 'ドライバー' : '2（支店管理者）か3（ドライバー）で入力してください'}}</span>
                    <span slot="mobile_model" slot-scope="mobile_model">{{ mobile_model }}</span>
                    <span slot="mobile_sim" slot-scope="mobile_sim">{{ mobile_sim }}</span>
                    <span slot="analyze_target" slot-scope="analyze_target" >{{ analyze_target == 0 ? '対象にしない' : analyze_target == 1 ? '対象にする' : '0（対象にしない）か 1（対象にする）で入力してください'}}</span>
                    <span slot="roll_call_agent" slot-scope="roll_call_agent" >{{ roll_call_agent == 0 ? '対象にしない' : roll_call_agent == 1 ? '対象にする' : '0（対象にしない）か、1（対象にする）で入力してください'}}</span>
                    <span slot="roll_call_count" slot-scope="roll_call_count" >{{ roll_call_count }}</span>

                    <span slot="roll_call_limited" slot-scope="roll_call_limited" >{{ roll_call_limited == 0 ? '全ての点呼を代行にする' : roll_call_limited == 1 ? '平日日中は代行にしない' : roll_call_limited == 2 ? '土日祝は代行にしない' : '0（全ての点呼を代行にする）か、1（平日日中は代行にしない）か、2（土日祝は代行にしない）で入力してください'}}</span>
                    <span slot="roll_call_able" slot-scope="roll_call_able" >{{ roll_call_able == 0 ? '権限に従う' : roll_call_able == 1 ? '支店管理者と同等にする' : '0（権限に従う）か、1（支店管理者と同等にする）で入力してください'}}</span>
                    <span slot="plus_flag" slot-scope="plus_flag" >{{ plus_flag == 0 ? '使用しない' : plus_flag == 1 ? '使用する' : '0（使用しない）か、1（使用する）で入力してください'}}</span>
                    <span slot="allow_driving_schedule" slot-scope="allow_driving_schedule" >{{ allow_driving_schedule == 0 ? '不許可' : allow_driving_schedule == 1 ? '許可' : '0（不許可）か、1（許可）で入力してください'}}</span>
                    <span slot="interlocking_detector" slot-scope="interlocking_detector" >{{ interlocking_detector == 0 ? '使用しない' : interlocking_detector == 1 ? '使用する' : '0（使用しない）か、1（使用する）で入力してください'}}</span>
                  </a-table>
                  <p v-if="!errorCount">以上のデータで作成してよろしいですか？</p>
                  <div class="steps-action">
                    <a-button v-if="!errorCount" type="primary" @click="createUser" :loading="createLoading">
                      作成する
                    </a-button>
                    <a-button style="margin-left: 8px" @click="prev">
                      戻る
                    </a-button>
                  </div>
                </div>
                <div v-if="current === 2">
                  <p>2番目</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import XLSX from 'xlsx'

export default {
  data() {
    return {
      myRole: 3,
      templateUrl: 'templates/user_template.xlsx',
      current: 0,
      createLoading: false,
      branchesAll: [],
      steps: [
        { title: 'エクセルでデータを作成' },
        { title: 'データの確認' },
        { title: '作成' },
      ],
      columns: [
        {
          title: 'ユーザー名',
          dataIndex: 'ユーザー名',
          scopedSlots: { customRender: 'name' },
          customCell: (text, row, index) => {
            if (!this.checkName(text['ユーザー名'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: 'ふりがな',
          dataIndex: 'ふりがな',
          scopedSlots: { customRender: 'phonetic' },
        },
        {
          title: 'メールアドレス',
          dataIndex: 'メールアドレス',
          scopedSlots: { customRender: 'email' },
          customCell: (text, row, index) => {
            if (!this.checkEmail(text['メールアドレス'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '権限',
          dataIndex: '権限',
          scopedSlots: { customRender: 'role' },
          customCell: (text, row, index) => {
            if (!this.checkRole(text['権限'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '所属支店',
          dataIndex: '所属支店',
          scopedSlots: { customRender: 'branches' },
          customCell: (text, row, index) => {
            if (!this.checkBranches(text['所属支店'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '電話番号',
          dataIndex: '電話番号',
          scopedSlots: { customRender: 'tel' },
          customCell: (text, row, index) => {
            if (!this.checkTel(text['電話番号'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: 'スマホ機種',
          dataIndex: 'スマホ機種',
          scopedSlots: { customRender: 'mobile_model' },
          customCell: (text, row, index) => {
            if (!this.checkMobileModel(text['スマホ機種'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: 'スマホのSIM',
          dataIndex: 'スマホのSIM',
          scopedSlots: { customRender: 'mobile_sim' },
          customCell: (text, row, index) => {
            if (!this.checkMobileSim(text['スマホのSIM'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '解析対象',
          dataIndex: '解析対象',
          scopedSlots: { customRender: 'analyze_target' },
          customCell: (text, row, index) => {
            if (!this.checkAnalyzeTarget(text['解析対象'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '点呼権限',
          dataIndex: '点呼権限',
          scopedSlots: { customRender: 'roll_call_able' },
          customCell: (text, row, index) => {
            if (!this.checkRollCallAble(text['点呼権限'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
      ],
      excelData: null,
      errorCount: 0,

      freeItemUserList: [],
    }
  },
  watch: {
    excelData: function(val) {
      const _this = this
      for (const row in val) {
        if (!_this.checkName(val[row]['ユーザー名'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkEmail(val[row]['メールアドレス'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkRole(val[row]['権限'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkBranches(val[row]['所属支店'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkTel(val[row]['電話番号'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkMobileModel(val[row]['スマホ機種'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkMobileSim(val[row]['スマホのSIM'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkAnalyzeTarget(val[row]['解析対象'])) {
          _this.errorCount++
          break
        }
        if (this.myRole === 0) {
          if (!_this.checkRollCallAgent(val[row]['点呼代行対象'])) {
            _this.errorCount++
            break
          }
          if (!_this.checkRollCallCount(val[row]['点呼代行回数'])) {
            _this.errorCount++
            break
          }
          if (!_this.checkRollCallLimited(val[row]['代行利用制限'])) {
            _this.errorCount++
            break
          }
          if (!_this.checkPlusFlag(val[row]['PLUSの使用'])) {
            _this.errorCount++
            break
          }
          if (!_this.checkAllowDrivingSchedule(val[row]['運行計画表の被閲覧'])) {
            _this.errorCount++
            break
          }
          if (!_this.checkInterlockingDetector(val[row]['連動検知器の使用'])) {
            _this.errorCount++
            break
          }
        }
      }
    },
  },
  created() {
    const myResult = Vue.prototype.$api.send('get', 'user')
    myResult.then(response => {
      this.myRole = response.role
      if (this.myRole === 0) {
        this.templateUrl = 'templates/user_master_template.xlsx'
        this.columns.push({
          title: '点呼代行対象',
          dataIndex: '点呼代行対象',
          scopedSlots: { customRender: 'roll_call_agent' },
          customCell: (text, row, index) => {
            if (!this.checkRollCallAgent(text['点呼代行対象'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        })
        this.columns.push({
          title: '点呼代行開始日',
          dataIndex: '点呼代行開始日',
          scopedSlots: { customRender: 'roll_call_date_start' },
        })
        this.columns.push({
          title: '点呼代行終了日',
          dataIndex: '点呼代行終了日',
          scopedSlots: { customRender: 'roll_call_date_end' },
        })
        this.columns.push({
          title: '点呼代行回数',
          dataIndex: '点呼代行回数',
          scopedSlots: { customRender: 'roll_call_count' },
          customCell: (text, row, index) => {
            if (!this.checkRollCallCount(text['点呼代行回数'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        })
        this.columns.push({
          title: '代行利用制限',
          dataIndex: '代行利用制限',
          scopedSlots: { customRender: 'roll_call_limited' },
          customCell: (text, row, index) => {
            if (!this.checkRollCallLimited(text['代行利用制限'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        })
        this.columns.push({
          title: 'PLUSの使用',
          dataIndex: 'PLUSの使用',
          scopedSlots: { customRender: 'plus_flag' },
          customCell: (text, row, index) => {
            if (!this.checkPlusFlag(text['PLUSの使用'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        })
        this.columns.push({
          title: '運行計画表の被閲覧',
          dataIndex: '運行計画表の被閲覧',
          scopedSlots: { customRender: 'allow_driving_schedule' },
          customCell: (text, row, index) => {
            if (!this.checkAllowDrivingSchedule(text['運行計画表の被閲覧'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        })
        this.columns.push({
          title: '連動検知器の使用',
          dataIndex: '連動検知器の使用',
          scopedSlots: { customRender: 'interlocking_detector' },
          customCell: (text, row, index) => {
            if (!this.checkInterlockingDetector(text['連動検知器の使用'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        })
      }
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 自身の情報取得に失敗しました。',
        })
      })
    const branchList = Vue.prototype.$api.send('get', 'branches')
    branchList.then(response => {
      this.branchesAll = response
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
      })
    const freeItemUserResult = Vue.prototype.$api.send('get', 'free_item_users')
    freeItemUserResult.then(response => {
      this.freeItemUserList = response
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザーの自由項目の取得に失敗しました。',
        })
        this.freeItemUserLoading = false
      })
  },
  methods: {
    branchesName(branches) {
      if (branches) {
        const bArr = String(branches).split(',')
        let rtnVal = ''
        for (let i = 0; i < bArr.length; i++) {
          const b = this.branchesAll.find((b) => b.id === Number(bArr[i]))
          if (b) {
            rtnVal += b.name + ','
          } else {
            rtnVal = '使用できない店舗IDが含まれています。'
            break
          }
        }
        return rtnVal
      } else {
        return '必須項目です。'
      }
    },
    next() {
      this.current++
    },
    prev() {
      this.current--
    },
    beforeUpload(file) {
      const _this = this
      _this.errorCount = 0
      console.log(file)
      const reader = new FileReader()
      reader.onload = function(file) {
        const data = new Uint8Array(file.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        console.log(workbook)
        _this.excelData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
      }
      reader.readAsArrayBuffer(file)
      this.current++
      return false
    },
    handleChange(info) {
      const status = info.file.status
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    checkName(val) {
      if (!val) {
        return false
      }
      return true
    },
    checkEmail(val) {
      const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
      if (!reg.test(val)) {
        return false
      }
      return true
    },
    checkRole(val) {
      if (val === 2 || val === 3) {
        return true
      }
      return false
    },
    checkBranches(val) {
      const reg = /^[0-9]+(,[0-9]+)*$/
      if (!reg.test(val)) {
        return false
      }
      const ids = String(val).split(',').map(Number)
      const exIds = this.branchesAll.filter(x => ids.includes(x.id))
      return ids.length === exIds.length
    },
    checkTel(val) {
      if (!val || val.length < 100) return true
      return false
    },
    checkMobileModel(val) {
      if (!val || val.length > 255) return false
      return true
    },
    checkMobileSim(val) {
      if (!val || val.length > 255) return false
      return true
    },
    checkAnalyzeTarget(val) {
      if (val === 0 || val === 1) {
        return true
      }
      return false
    },
    checkRollCallAgent(val) {
      if (val === 0 || val === 1) {
        return true
      }
      return false
    },
    checkRollCallCount(val) {
      if (val >= 0) {
        return true
      }
      return false
    },
    checkRollCallLimited(val) {
      if (val === 0 || val === 1) {
        return true
      }
      return false
    },
    checkRollCallAble(val) {
      if (val === 0 || val === 1) {
        return true
      }
      return false
    },
    checkPlusFlag(val) {
      if (val === 0 || val === 1) {
        return true
      }
      return false
    },
    checkAllowDrivingSchedule(val) {
      if (val === 0 || val === 1) {
        return true
      }
      return false
    },
    checkInterlockingDetector(val) {
      if (val === 0 || val === 1) {
        return true
      }
      return false
    },
    createUser() {
      this.createLoading = true
      const result = Vue.prototype.$api.send('post', 'users/bulk', { users: this.excelData })
      result.then(response => {
        this.$notification['success']({
          message: 'ユーザーを作成しました',
        })
        this.$router.push('/users')
        this.createLoading = false
      })
        .catch(error => {
          let duration = 4.5
          if (error.status === 500) {
            duration = 15
          }
          this.$notification['error']({
            message: error.status + ': ' + error.data.data,
            duration: duration,
          })
          this.createLoading = false
        })
    },
  },
}
</script>

<style>
.wrongCell {
  background-color: #FED1D1;
}
</style>
